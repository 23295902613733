import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { SanityRecipe } from "@graphql-types";
import { Container, P } from "@util/standard";
import PreviewAllergens from "./sub/previewAllergens";
import { Image } from "@global";
import { colors, colorsRGB } from "@util/constants";

interface Props {
  recipe: SanityRecipe;
  isSearchResult?: boolean;
  scalableWidths?: boolean;
  isCategoryResult?: boolean;
}

const GradientContainer = styled(Container)`
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55));
  position: relative;
  z-index: 2;
  pointer-events: none;
`;

const SquareLink = styled(Link)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${colorsRGB.black(0)};
  transition: 0.3s background ease;
  cursor: pointer;
  &:hover {
    opacity: 1;
    background: ${colorsRGB.black(0.3)};
  }
`;

const MetaP = styled(P)<{
  displayMeta: boolean;
}>`
  ${({ displayMeta }) =>
    displayMeta
      ? `
    transform: scaleY(1);
    height: auto;
  `
      : `
    height: 0;
    transform: scaleY(0);
  `}
  overflow: hidden;
  transition: all 0.3s ease;
  font-size: 16px;
  color: ${colors.white};
  margin: 10px 20px 20px;
  span {
    &:after {
      content: "|";
      display: inline-block;
      margin: 0 5px;
    }
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
`;

const RecipePreview = (props: Props) => {
  const { recipe, isSearchResult, scalableWidths, isCategoryResult } = props;
  const [displayMeta, setDisplayMeta] = useState(false);

  let info: any;
  if (isSearchResult) {
    info = recipe.info;
  } else {
    info = recipe?.info;
  }

  if (info == null) {
    return null;
  }

  return (
    <Container
      margin="0px 25px 25px 0px"
      display="flex"
      flexDirection="column"
      mobileMargin={isCategoryResult ? "20px 10px" : "20px auto"}
      onMouseEnter={() => setDisplayMeta(true)}
      onMouseLeave={() => setDisplayMeta(false)}
    >
      <Image
        isBackground
        imageData={info.featuredMedia?.image?.asset?.gatsbyImageData}
        mobileImage={info.featuredMedia?.image?.mobileImage?.asset?.gatsbyImageData}
      >
        <Container
          width="265px"
          height="275px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          position="relative"
        >
          <SquareLink to={`/recipe/${info.slug?.current}`}></SquareLink>
          <Container width="100%" position="relative" zIndex={2}>
            <PreviewAllergens recipeInfo={info} id={recipe.id} />
          </Container>
          <GradientContainer>
            <P
              margin="20px 20px 0 20px"
              textAlign="left"
              bold
              color="white"
              fontSize={20}
            >
              {info.title}
            </P>
            <MetaP displayMeta={displayMeta}>
              {/* {info.prepTime && <span>{info.prepTime}mins Prep</span>}
              {info.cookTime && <span>{info.cookTime}mins Cook</span>} */}
            </MetaP>
          </GradientContainer>
        </Container>
      </Image>
    </Container>
  );
};

export default RecipePreview;
